import React, { useMemo } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { compareAsc, differenceInCalendarDays, parseISO } from 'date-fns';
import ResourceTable from '../../table/components/resource/ResourceTable';
import {
    NURSE_DEPLOYMENT_RESOURCE,
    NURSE_TRANSFER_RESOURCE,
    CUSTOMER_RESOURCE,
} from '../../api/resources';
import Agency from '../../agencies/components/Agency';
import CustomerNames from '../../customers/components/CustomerNames';
import DisplayDate from '../../date/components/DisplayDate';
import A1State from '../../a1document/components/A1State';
import { useResourceDialog } from '../../dialogs/hooks';
import TransferDate from '../../nurseTransfers/components/TransferDate';
import { TRANSFER_TYPE_ARRIVAL, TRANSFER_TYPE_DEPARTURE } from '../../nurseTransfers/types';
import Location from '../../locations/components/Location';

const columns = [
    {
        key: 'agencyId',
        label: 'Agentur',
        component: Agency,
    },
    {
        key: 'customerId',
        label: 'Au.Nr.',
    },
    {
        key: 'customerNames',
        accessor: 'customerId',
        label: 'Kunde',
        component: ({ customerId }) => <CustomerNames customerId={customerId} linkPzhOnly />,
    },
    {
        key: 'locationId',
        related: 'customer',
        label: 'Standort',
        sortable: false,
        component: Location,
    },
    {
        key: 'arrivalTransfer.date',
        related: 'arrivalTransfer',
        label: 'Anreise',
        sortable: false,
        component: ({ value, data }) => (
            <TransferDate
                deploymentId={data.id}
                fallback={data.careFrom}
                type={TRANSFER_TYPE_ARRIVAL}
            />
        ),
    },
    {
        key: 'departureTransfer.date',
        related: 'departureTransfer',
        sortable: false,
        label: 'Abreise',
        component: ({ value, data, related }) => (
            <TransferDate
                deploymentId={data.id}
                fallback={data.careTill}
                type={TRANSFER_TYPE_DEPARTURE}
            />
        ),
    },
    {
        key: 'id',
        label: 'A1',
        component: A1State,
        as: 'nurseDeploymentId',
    },
];

const NurseHistory = ({ nurseId }) => {
    const extraActions = useMemo(
        () => [
            // {
            //     key: 'link',
            //     compact: ({ data: { id } }) => <CopyIconButton size="small" color="primary" />,
            // },
        ],
        []
    );

    const handleEdit = useResourceDialog(NURSE_DEPLOYMENT_RESOURCE);

    const fetchParams = useMemo(
        () => ({
            nurseId,
        }),
        [nurseId]
    );

    return (
        <>
            <Grid item xs={12}>
                <ResourceTable
                    columns={columns}
                    resource={NURSE_DEPLOYMENT_RESOURCE}
                    actions={extraActions}
                    with={{
                        customer: CUSTOMER_RESOURCE,
                        arrivalTransfer: NURSE_TRANSFER_RESOURCE,
                        departureTransfer: NURSE_TRANSFER_RESOURCE,
                    }}
                    fetchParams={fetchParams}
                    showActionsInline={2}
                    autoload
                    onEdit={handleEdit}
                />
            </Grid>
        </>
    );
};

export default NurseHistory;
