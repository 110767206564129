import React from 'react';
import { CurrencyPropType } from '../../currencies/proptypes';
import Currency from '../../currencies/components/Currency';
import { calculateFinalPrice } from '../utils';

const NursePrice = ({ fixedPrice, priceAgency, surchargeAgency, provisionPZH, surchargePZH }) => {
    return (
        <Currency
            value={calculateFinalPrice({
                fixedPrice,
                priceAgency,
                surchargeAgency,
                provisionPZH,
                surchargePZH,
            })}
        />
    );
};

NursePrice.propTypes = {
    fixedPrice: CurrencyPropType,
    priceAgency: CurrencyPropType,
    surchargeAgency: CurrencyPropType,
    provisionPZH: CurrencyPropType,
    surchargePZH: CurrencyPropType,
};

NursePrice.defaultProps = {
    fixedPrice: null,
    priceAgency: null,
    surchargeAgency: null,
    provisionPZH: null,
    surchargePZH: null,
};

export default NursePrice;
