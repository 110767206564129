import { format, parseISO } from 'date-fns';

const moment = require('moment');

export const update = (state, mutations) => ({ ...state, ...mutations });

export const insertItem = (array, action) => [
    ...array.slice(0, action.index),
    action.item,
    ...array.slice(action.index),
];

export const appendItem = (array, item) => [...array, item];

export const removeItem = (array, action) => [
    ...array.slice(0, action.index),
    ...array.slice(action.index + 1),
];

export const removeItemByProp = (array, prop, value) => {
    const newArray = [...array];

    for (const key in newArray) {
        const item = newArray[key];
        if (item[prop] === value) {
            // This is the item we care about - remove it!
            newArray.splice(key, 1);
        }
    }

    return newArray;
};

export const updateObjectInArray = (array, action) =>
    array.map((item, index) => {
        if (index !== action.index) {
            // This isn't the item we care about - keep it as-is
            return item;
        }

        // Otherwise, this is the one we want - return an updated value
        return {
            ...item,
            ...action.item,
        };
    });

export const updateObjectInArrayById = (array, id, newItem) =>
    array.map((item, index) => {
        if (item.id !== id) {
            // This isn't the item we care about - keep it as-is
            return item;
        }

        // Otherwise, this is the one we want - return an updated value
        return {
            ...item,
            ...newItem,
        };
    });

export const findObjectInArrayByProp = (array, prop, value) => {
    if (!Array.isArray(array)) {
        return false;
    }

    for (const i in array) {
        const item = array[i];
        if (item[prop] && item[prop] === value) {
            return item;
        }
    }

    return false;
};

export const findObjectInArrayById = (array, id) => findObjectInArrayByProp(array, 'id', id);

export const findObjectInArrayByKey = (array, key) => findObjectInArrayByProp(array, 'key', key);

export const getNested = (obj, key) =>
    key
        .split('.')
        .reduce(
            (value, keyPart) =>
                value !== undefined && value !== null ? value[keyPart] : undefined,
            obj
        );

export const normalizePhone = (value) => {
    if (!value) {
        return value;
    }

    return value
        .replace(/[^\d\s]/g, '') // replace everything that is not digit, plus or space
        .replace(/^\s+/g, ''); // replace everything that is not digit, plus or space
    // .replace(/\s+/g, ' ') // replace multiple spaces to one
    // .trim()
};

export const getNursePrice = (deployment) => {
    let priceAgency;
    if (deployment.priceAgency) {
        priceAgency = deployment.priceAgency
            ? parseFloat(deployment.priceAgency.replace(',', '.'))
            : null;
        priceAgency = priceAgency.toString();
    }

    const prices = [
        priceAgency,
        deployment.surchargeAgency,
        deployment.provisionPZH,
        deployment.surchargePZH,
    ];

    const nonNull = prices.filter((value) => value);
    const floats = nonNull.map((value) => parseFloat(value.replace(',', '.')));
    const sum = floats.reduce((a, b) => a + b, null);
    /*
     if (sum && deployment.priceAgencyDaily) {
     return sum * 30;
     }
     */
    return sum;
};

export const renderContactName = (contact) =>
    [contact.lastname, contact.firstname].filter((item) => item).join(', ');

export const renderContactNameDetailed = (contact) => {
    const salutationChoices = { male: 'Herr', female: 'Frau', unknown: null };
    return [
        contact.salutation ? salutationChoices[contact.salutation] : null,
        contact.title ? contact.title : null,
        contact.firstname,
        contact.lastname,
    ]
        .filter((item) => item)
        .join(' ');
};

export const uuidv4 = () =>
    'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = (Math.random() * 16) | 0;
        const v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });

export const reducePatch = (state, action) => {
    if (action.data.request && action.data.request.idField) {
        const { fieldMapping, id, idField, values } = action.data.request;

        const items = state.items.map((item) => {
            if (item[idField] !== parseInt(id, 10)) return item;

            if (!fieldMapping) {
                return {
                    ...item,
                    ...values,
                };
            }

            return Object.entries(values).reduce(
                (acc, [key, value]) => {
                    acc[fieldMapping[key]] = value;
                    return acc;
                },
                { ...item }
            );
        });

        return {
            ...state,
            items,
        };
    }
    return state;
};

export const compareDate = (aDate, bDate) => {
    if (aDate && bDate) {
        return aDate.localeCompare(bDate);
    }
    if (aDate) {
        return -1;
    }
    if (bDate) {
        return 1;
    }
    return 0;
};

export const compareState = (aState, bState, state) => {
    if (aState === state && bState === state) {
        return 0;
    }
    if (aState === state) {
        return -1;
    }
    if (bState === state) {
        return 1;
    }
    return undefined;
};

export const compareTravelImminentPrepsDone = (a, b) => {
    if (a.travelImminent && a.travelPrepsDone && b.travelImminent && b.travelPrepsDone) {
        return 0;
    }
    if (a.travelImminent && a.travelPrepsDone) {
        return -1;
    }
    if (b.travelImminent && b.travelPrepsDone) {
        return 1;
    }
    return undefined;
};

export const compareTravelImminentPrepsNotDone = (a, b) => {
    if (a.travelImminent && !a.travelPrepsDone && b.travelImminent && !b.travelPrepsDone) {
        return 0;
    }
    if (a.travelImminent && !a.travelPrepsDone) {
        return -1;
    }
    if (b.travelImminent && !b.travelPrepsDone) {
        return 1;
    }
    return undefined;
};

export const compareReminder = (a, b) => {
    if (a && b) {
        return compareDate(a, b);
    }
    if (a) {
        return -1;
    }
    if (b) {
        return 1;
    }
    return undefined;
};

export const compareName = (c1, c2) => {
    const cmpLastname = c1.lastname.localeCompare(c2.lastname);
    if (cmpLastname !== 0) {
        return cmpLastname;
    }
    return c1.firstname.localeCompare(c2.firstname);
};

export const compareCustomerByLastname = (c1, c2) => {
    return compareName(c1.displayContact, c2.displayContact);
};

export const getPrefixedKey = (key, prefix) =>
    prefix ? `${prefix}${key.replace(/^\w/, (c) => c.toUpperCase())}` : key;

export const contactPhoneBestGuess = (phoneNumbers) => {
    const numbers = phoneNumbers.filter((number) => !number.type.toLowerCase().includes('fax'));
    return numbers.find((number) => number.type.toLowerCase().includes('privat'));
};

export const contactMobileBestGuess = (phoneNumbers) => {
    const numbers = phoneNumbers.filter((number) => !number.type.toLowerCase().includes('fax'));
    return numbers.find((number) => number.type.toLowerCase().includes('mobil'));
};

export const contactBusinessBestGuess = (phoneNumbers) => {
    const numbers = phoneNumbers.filter((number) => !number.type.toLowerCase().includes('fax'));

    const businessPhone = numbers
        .filter((number) => number.type.toLowerCase().includes('geschäftl'))
        .sort((number) => number.type.toLowerCase())[0];
    if (businessPhone) {
        return businessPhone;
    }

    return contactMobileBestGuess(numbers) || numbers[0];
};

const groupedNumber = (number) =>
    number !== undefined &&
    number
        .toString()
        .replace(/\s+/g, '')
        .replace(/(.{3})/g, '$1 ');

const formatCountryCode = (countryCode) =>
    countryCode ? countryCode.replace(/^00/, '+') : countryCode;

export const formatPhoneNumber = (number, optionalZero) => {
    if (!number) {
        return '';
    }

    // Wenn Ländercode und Vorwahl vorhanden und die Vorwahl mit einer 0 anfängt
    if (number.countryCode && number.prefix && number.prefix.indexOf(0) === 0) {
        // Lasse die führende Null in der Vorwahl weg, weil der Ländercode diese ersetzt
        return `${formatCountryCode(number.countryCode)} ${
            optionalZero ? '(0)' : ''
        }${number.prefix.slice(1, number.prefix.length)} ${groupedNumber(number.number)}`;
    }
    // Ansonsten concateniere alle nicht-null Felder
    return [formatCountryCode(number.countryCode), number.prefix, groupedNumber(number.number)]
        .filter((n) => n)
        .join(' ');
};

export const humanFileSize = (bytes, si) => {
    const thresh = si ? 1000 : 1024;
    if (Math.abs(bytes) < thresh) {
        return `${bytes} B`;
    }
    const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    do {
        bytes /= thresh;
        ++u;
    } while (Math.abs(bytes) >= thresh && u < units.length - 1);
    const size = `${bytes.toFixed(1)} ${units[u]}`;
    return size.replace('.', ',');
};

// TODO: codebase refactoren auf ausschließliche Nutzung der uppercase Konstanten für datumsformate
export const displayFormat = 'DD.MM.YYYY';
export const dateFormat = 'YYYY-MM-DD';
export const DISPLAY_FORMAT = displayFormat;
export const DATE_FORMAT = dateFormat;
export const DATETIME_DISPLAY_FORMAT = 'DD.MM.YYYY HH:mm';
export const DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

// Function to format dates when used in strings. Otherwise use <DisplayDate> component
export const formatDate = (str, customFormat) =>
    str ? format(parseISO(str), customFormat || 'dd.MM.yyyy') : '';

export const formatDateTime = (str, customFormat) =>
    str ? format(parseISO(str), customFormat || 'dd.MM.yyyy HH:mm') : '';

export const getTitleFromUrl = (url, override, suffix) => {
    let parts =
        url in override
            ? [...override[url]]
            : url
                  .split('/')
                  .reverse()
                  .filter((part) => part)
                  .map((part) => part[0].toUpperCase() + part.slice(1));
    if (suffix) {
        parts = parts.concat(suffix);
    }
    parts.push('PZH-CRM');
    return parts.join(' | ');
};

export const parseDateFromString = (dateString, seperator) => {
    //console.log('parseDateFromString', dateString,seperator);
    const [year, month, day] = dateString.split(seperator).map(Number);
    return new Date(year, month - 1, day);
};

export const reminderIsOverdue = (reminder) =>
    moment(reminder.dueAt, dateFormat).diff(moment().startOf('day'), 'days', true) < 0;

export const addValueToString = (existingString, newValue) => {
    return existingString === "" ? newValue : `${existingString},${newValue}`;
  };