import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { SubjectPropType } from '../../../abilities/proptypes';
import SendMailButton from './SendMailButton';
import { TRAVEL_DETAILS_ENTITY } from '../../../mailTemplates/entities';
import { IdPropType } from '../../../../proptypes/basic';
import { ExtraEntitiesPropType } from '../../../mails/proptypes';
import { NURSE_DEPLOYMENT_RESOURCE } from '../../../api/resources';

const SendNurseTransferButton = ({
    customerId,
    deploymentId,
    type,
    onSent,
    extraEntities,
    disabled,
    subject,
    action,
    className,
    variant,
    color,
    size,
}) => {
    // TODO: extract to dynamic mail attachment somehow
    const attachments = useMemo(
        () => [
            {
                filename: 'Reisetabelle',
                payload: {
                    id: deploymentId,
                    resource: NURSE_DEPLOYMENT_RESOURCE,
                    data: { type },
                },
                path: '',
                locked: true,
                generate: true,
            },
        ],
        [deploymentId, type]
    );

    return (
        <SendMailButton
            name="send_nurse_transfer"
            variant={variant}
            color={color}
            disabled={disabled}
            action={action}
            subject={subject}
            entity={TRAVEL_DETAILS_ENTITY}
            entityId={customerId}
            onSent={onSent}
            extraEntities={extraEntities}
            attachments={attachments}
            className={className}
            size={size}
            label="Reisetabelle verschicken"
        />
    );
};

SendNurseTransferButton.propTypes = {
    customerId: IdPropType.isRequired,
    deploymentId: IdPropType.isRequired,
    type: PropTypes.string.isRequired,
    onSent: PropTypes.func,
    extraEntities: ExtraEntitiesPropType,
    disabled: PropTypes.bool,
    subject: SubjectPropType,
    action: PropTypes.string,
    variant: PropTypes.string,
    color: PropTypes.string,
    size: PropTypes.string,
    className: PropTypes.string,
};

SendNurseTransferButton.defaultProps = {
    onSent: null,
    extraEntities: null,
    disabled: false,
    subject: null,
    action: null,
    variant: undefined,
    color: undefined,
    size: undefined,
    className: null,
};

export default SendNurseTransferButton;
