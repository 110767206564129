import React from 'react';
import { useField } from 'formik';
import NursePrice from '../../nurses/components/NursePrice';

const NursePriceFieldView = () => {
    // If needed on any place other than nurseDeployment, make NAME a variable
    const [{ value: fixedAgencyPrice }] = useField('fixedAgencyPrice');
    const [{ value: priceAgency }] = useField('priceAgency');
    const [{ value: surchargeAgency }] = useField('surchargeAgency');
    const [{ value: provisionPZH }] = useField('provisionPZH');
    const [{ value: surchargePZH }] = useField('surchargePZH');
    
    return (
        <NursePrice
            fixedPrice={fixedAgencyPrice}
            priceAgency={priceAgency}
            surchargeAgency={surchargeAgency}
            provisionPZH={provisionPZH}
            surchargePZH={surchargePZH}
        />
    );
};

export default NursePriceFieldView;
