export const calculateFinalPrice = ({
    fixedPrice = null,
    priceAgency = null,
    surchargeAgency = null,
    provisionPZH = null,
    surchargePZH = null,
}) => {
    // Convert inputs to floating-point numbers if they are not null
    fixedPrice = fixedPrice !== null ? parseFloat(fixedPrice) : null;
    priceAgency = priceAgency !== null ? parseFloat(priceAgency) : 0;
    surchargeAgency = surchargeAgency !== null ? parseFloat(surchargeAgency) : 0;
    provisionPZH = provisionPZH !== null ? parseFloat(provisionPZH) : 0;
    surchargePZH = surchargePZH !== null ? parseFloat(surchargePZH) : 0;

    if (fixedPrice !== null && !isNaN(fixedPrice)) {
        return fixedPrice;
    }

    const priceComponents = [priceAgency, surchargeAgency, provisionPZH, surchargePZH];
    const finalPrice = priceComponents.reduce(
        (total, price) => total + (isNaN(price) ? 0 : price),
        0
    );

    return finalPrice;
};
