import React from 'react';
import * as PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Checkbox from '../../form/components/Checkbox';
import BaseDateInput from '../../form/components/base/BaseDateInput';

import { IdPropType } from '../../../proptypes/basic';

const CheckInOutDisable = ({ nameArr, nameDep, labelArr, labelDep, xs,  arrivalAt, departureAt}) => {

    return (
        <>
            <Grid item xs={xs}>
                <BaseDateInput
                    value={arrivalAt?.date || null}
                    name="arrival.date"
                    label="Anreise (aus Reisedetails)"
                    disabled
                    fullWidth
                />
                <Checkbox name={nameArr} label={labelArr} />
            </Grid>
            <Grid item xs={xs}>
                <BaseDateInput
                    value={departureAt?.date || null}
                    name="departure.date"
                    label="Abreise (aus Reisedetails)"
                    disabled
                    fullWidth
                />
                <Checkbox name={nameDep} label={labelDep} />
            </Grid>
        </>
    );
};

CheckInOutDisable.propTypes = {
    deploymentId: IdPropType,
    nameArr: PropTypes.string.isRequired,
    nameDep: PropTypes.string.isRequired,
    labelArr: PropTypes.string.isRequired,
    labelDep: PropTypes.string.isRequired,
    arrivalAt: PropTypes.object,
    departureAt: PropTypes.object,
    xs: PropTypes.number,
};

CheckInOutDisable.defaultProps = {
    deploymentId: null,
    arrivalAt: null,
    departureAt: null,
    xs: 3,
};

export default CheckInOutDisable;
